@import "../../../assets/styles/mixin.scss";

.auth-layout-container {
  display: flex;
  justify-content: center;
  padding: 2rem;

  .auth-layout-image {
    max-width: 100%;
  }
}
