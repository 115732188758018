.header-wrapper {
  position: relative;
  z-index: 100;

  .header-container {
    background: var(--off-white-color);
    backdrop-filter: blur(6px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
    border-radius: 0.3rem;
    padding: 4px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .profile-container {
      width: 35px;
      aspect-ratio: 1;
      border-radius: 50%;
      cursor: pointer;
      background-color: var(--light-grey-color);
      color: var(--pure-white-color);
      user-select: none;
    }

    .menu-wrapper {
      position: absolute;
      top: calc(100% + 0.35rem);
      right: 0;
      // width: 280px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s;

      .menu {
        position: relative;
        z-index: 9999;
        background-color: var(--pure-white-color);
        padding: 20px;
        margin: 10px;
        gap: 0.6rem;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
          0 3px 10px 0 rgba(0, 0, 0, 0.09);
        border-radius: 0.3rem;
        border: 1px solid var(--light-blue-color);

        .user-details{
          word-break: break-all;
        }

        .user-details > *{
          color: var(--grey-color);
        }

        .inner-profile-container{
          width: 45px;
          min-width: 45px;
          font-size: var(--font-size-24);
        }

        .horizontal-rule {
          width: 100%;
          height: 1px;
          border: 0;
          background: var(--chinese-silver-color);
        }

        .user-info {
          gap: 1.5rem;
        }

        .menu-link {
          gap: 0.7rem;
          cursor: pointer;
          color: var(--davys-gray-color);

          &-icon {
            width: 50px;
            aspect-ratio: 1;
            border-radius: 50%;
            background-color: var(--platinum-color);
          }

          &-text {
            width: 100%;
            font-size: var(--font-size-14);
          }

          &-arrow{
            font-size: 1.5rem;
            transition: transform 0.5s;
          }
        }
        .menu-link:hover .menu-link-text {
          font-weight: 600;
        }
        
        .menu-link:hover .menu-link-arrow{
            transform: translateX(5px);
        }
      }
    }

    .menu-wrapper::before {
      content: "";
      position: absolute;
      width: 20px;
      aspect-ratio: 1;
      background-color: var(--pure-white-color);
      transform: rotate(45deg);
      top: 3px;
      right: 2rem;
      border: 1px solid var(--light-blue-color);
    }

    .menu-wrapper.menu-wrapper-open {
      max-height: 400px;
    }
  }
}
