/* UTILITY CLASSES */
/* FLEX BOX */
.flex_box {
  display: flex;
}

.flex_box--items-center {
  align-items: center;
}

.flex_box--text-center {
  text-align: center;
}

.flex_box--justify-center {
  justify-content: center;
}

.flex_box--space-around {
  justify-content: space-around;
}

.flex_box--space-between {
  justify-content: space-between;
}

.flex_box--end {
  justify-content: flex-end;
}

.flex_box--start {
  justify-content: flex-start;
}

.flex_box--row {
  flex-direction: row;
}

.flex_box--column {
  flex-direction: column;
}

$gap:(1,2,3,4,5,6,7,8,9,10);

@each $i in $gap{
  .gap-#{$i}{
    gap: #{$i}rem;
  }
}


/* text info for details */
.label_info{
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-14);
  color: var(--grey-color);
}
/* text info for details */



/* Add/View style to open notes dialog from table */
.add_view_notes_button {
  color: var(--deep-blue-color);
  font-family: var(--default-font-family);
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-400);
}
/* Add/View style to open notes dialog from table */