$table-container-width: (
  "xs": 444px,
  "sm": 600px,
  "md": 900px,
  "lg": 1200px,
  "xl": 1536px,
);

.table-wrapper{
  max-width:1440px;
  overflow: auto;
}

.table-container{
  overflow: auto;
  margin-inline: auto;
  width: 100%;

  @each $key, $value in $table-container-width {
    &-width-#{$key} {
      max-width: $value;
      width: calc(100% - 64px);
    }
  }
  
}

.main-table {
	border-collapse:collapse;
	border-spacing:0;
  width: 100%;
}

caption,
.table-header,
.table-data {
  text-align: center;
  padding: 1rem;
}

.table-header{
  color: var(--midnight-blue-color);
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-600);
  text-transform: uppercase;
  font-style: normal;
  cursor: pointer;
}

.table-data{
  color: var(--midnight-blue-color);
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-400);
  font-style: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sorting-container{
  margin-left: 0.4rem;
}

.table-header-row{
    border-top: 1px solid var(--border, var(--light-blue-gray-color));
    border-bottom: 1px solid var(--border, var(--light-blue-gray-color));
}

.table-row:nth-child(2n) {
  border-bottom: 1px solid var(--border, var(--light-blue-gray-color));
  background: var(--pale-blue-color);
}

.table-row {
  border-bottom: 1px solid var(--border, var(--light-blue-gray-color));
}

.no-records-found-message{
  text-align: center;
  color: var(--grey-color);
  background-color: var(--pure-white-color);
  padding: 1rem;
}



/* Media queries */
@media(max-width:750px){
  .table-data{
    display: block;
    padding: 0.75rem 1rem;
  }

  .table-data::before{
    content: attr(data-cell) " : ";
    text-transform: capitalize;
    font-weight: bold;
  }

  .table-header{
    display: none;
  }
}



@media(max-width:1540px){
  .table-container-default{
    width: calc(100vw - 15rem);
  }
}