.tooltip-wrapper {
  position: relative;
  z-index: 100;
  
    .tooltip {
      cursor: pointer;
      position: relative;
      z-index: 100;

      &:before, &:after {
        position: absolute;
        pointer-events: none;
        transition: 0.3s;
        left: -10px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
        border: 1px solid var(--light-blue-color);
      }

      &:before {
        content: attr(data-tooltip);
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-500);
        color: var(--primary-color);
        background-color: var(--pure-white-color);
        width: max-content;
        z-index: 1;
        padding: 0.4rem 1rem;
        border-radius: 5px;
        opacity: 0;
        transform: scale(0);
      }

      &:after {
        content: "";
        position: absolute;
        width: 10px;
        aspect-ratio: 1;
        background-color: var(--pure-white-color);
        border: 1px solid var(--light-blue-color);
        opacity: 0;
        transform: scale(0) rotate(45deg);
        top: 0;
        bottom: 0;
        margin: auto 0;
      }

      &:hover:before, &:hover:after {
        opacity: 1;
      }
      &:hover:before{
        left: 65px;
        transform: scale(1);
      }
      &:hover:after {
        left: 60px;
        transform: scale(1) rotate(45deg);
        
      }
    }
  }