$spinner-size: (
  "sm": 30px,
  "md": 60px,
  "lg": 90px,
);

.spinner-container{
  min-height: calc(100vh - 20rem);
  display: flex;
  align-items: center;
  justify-content: center;
}


.spinner {
  width: 60px;
  aspect-ratio: 1;
  border: 8px solid var(--white-smoke-color);
  border-top: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: spinner linear 1.2s infinite;

  @each $key, $value in $spinner-size{
    &-size-#{$key}{
      width: $value;
    }
  }
  
  &-default-size{
    width: 60px;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
